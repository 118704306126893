<template>
  <div class="flex justify-between mt-5 mb-3">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="mr-2 p-button-danger"
            @click="$router.go(-1)"
    />
  </div>
  <div class="box mb-4">
    <div class="grid grid-cols-2 gap-4 p-4">
      <span><span class="font-bold">Nombre Licitacion:</span> {{ licitacion.nombre }}</span>
      <span><span class="font-bold">Fecha Cierre: </span>{{ licitacion.fechaCierre }}</span>
    </div>
  </div>

  <Accordion @tab-open="abrio" @tab-close="cerro" :activeIndex="activeIndex">
    <AccordionTab v-for="(carpeta, index) of dataCargar" :key="index">
      <template #header>
        <i class="pi pi-folder" :id="`myAcordion_${index}`" />
        <span class="ml-2">{{index}}-{{ carpeta.nombre }}</span>
      </template>
        <DataTable :value="carpeta.CarpetasDocumentos" responsiveLayout="scroll" v-model:filters="filters1" :globalFilterFields="['Documento.nombre']">
          <template #header>
            <div class="flex justify-between">
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined" @click="clearFilter1()"/>
              <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters1['global'].value" placeholder="Buscar Documento" />
                        </span>
            </div>
          </template>
          <Column field="id" header="Nombre Doc." style="width: 40vh">
            <template #body="prop">
              <span>{{prop.index}}-{{prop.data.Documento.nombre}}</span>
            </template>
          </Column>
          <Column field="responsableId" header="Responsable" style="width: 30vh">
            <template #body="{data}">
              <Dropdown class="w-full" v-model="data.responsableId" :options="users" optionLabel="fullName" option-value="id" placeholder="Seleccione un usuario" :filter="true" filterPlaceholder="Buscar Usuario" @change="onUser($event, data.id)"/>
            </template>
          </Column>
          <Column field="path" header="Estado">
            <template #body="{data}">
              <Tag v-if="data.path" severity="success">Cargado</Tag>
              <Tag v-else severity="danger">Pendiente</Tag>
            </template>
          </Column>
          <Column field="fechacarga" header="Fecha Carga" />
          <Column field="usercarga" header="Usuario Carga">
            <template #body="{data}">
              <span v-if="data.UserCarga">{{data.UserCarga.fullName}}</span>
              <span v-else>---</span>
            </template>
          </Column>
          <Column header="Acciones" style="width: 20vh">
            <template #body="{data}">
              <div class="flex justify-around">
                <Button icon="pi pi-eye"
                        class="mr-2"
                        style="height: 40px; width: 40px;"
                        @click="verDocumento(data.path)"
                        v-tooltip.top="'Ver Pdf'"
                />
                <Button icon="pi pi-upload"
                        class="mr-2 p-button-success"
                        style="height: 40px; width: 40px;"
                        @click="cargarDocumento(data.id)"
                        v-tooltip.top="'Cargar Pdf'"
                />
                <Button icon="pi pi-trash"
                        class="mr-2 p-button-danger"
                        style="height: 40px; width: 40px;"
                        @click="eliminarDocumento(data.id)"
                        v-tooltip.top="'Eliminar Pdf'"
                />
                <Button icon="pi pi-inbox"
                        class="mr-2 p-button-help"
                        style="height: 40px; width: 40px;"
                        @click="$router.push({name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas', params: { licCarpetasDocumentosId: data.id}})"
                        v-tooltip.top="'Ver Tareas'"
                />
              </div>
            </template>
          </Column>
        </DataTable>
    </AccordionTab>
  </Accordion>

  <Dialog v-model:visible="modalPdf" :style="{width: '80%'}" ref="modalEmbedPdf" contentStyle="overflow: hidden" header="Ver documento" :modal="true" maximizable class="p-fluid">
    <div id="embedPdf" style="display: block;width: 100%">
      <embed style="width: 100%" :src="rutaConsolidado" frameborder="0"
             id="pdf"
             width="100%"
             height="750rem"
      />
    </div>
    <div id="cargando" style="display: none; justify-content: center; align-items: center">
      <ProgressSpinner />
    </div>
    <template #footer>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div class="w-3/4" style="display: flex; align-items: center">
          <Button label="Girar"
                  icon="pi pi-replay"
                  class="mr-2 p-button-warning"
                  @click="girarPdf"
          />
          <div v-for="item of giros" :key="item.key" class="field-radiobutton ml-2">
            <RadioButton :id="item.key" name="category" :value="item.key" v-model="giro" />
            <label :for="item.key">{{item.nombre}}</label>
          </div>
          <InputNumber v-model="pageNumber" placeholder="No. Pag." style="width: 20%; margin-left: 4px"/>
        </div>
      </div>
    </template>
  </Dialog>

  <input type="file"
         ref="documento"
         id="documento"
         accept="application/pdf"
         @change="onUpload($event.target)"
         class="hidden"
  />
  <Toast/>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import LicitacionesService from '../../services/LicitacionesService'
import { useToast } from 'primevue/usetoast'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'cargarDocumentosLicitaciones',
  components: {
    Accordion,
    AccordionTab
  },
  setup () {
    const route = useRoute()
    const dataCargar = ref([])
    const toast = useToast()
    const _LicitacionesService = ref(new LicitacionesService())
    const documento = ref()
    const cargarDocumentoId = ref(null)
    const licitacion = ref({
      nombre: '',
      fechaCierre: ''
    })
    const users = ref([])
    const expandedRowGroups = ref(null)
    const activeIndex = ref([])
    const filters1 = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const modalPdf = ref(false)
    const rutaConsolidado = ref('')
    const pathConsolidado = ref(null)
    const pageNumber = ref(null)
    const giro = ref(null)
    const giros = ref([
      { nombre: '0°', key: '0' },
      { nombre: '90°', key: '90' },
      { nombre: '180°', key: '180' },
      { nombre: '270°', key: '270' }
    ])
  const modalEmbedPdf = ref()
    onMounted(() => {
      getOnLoad()
      getUsers()
    })

    // Methods
    const getOnLoad = () => {
      _LicitacionesService.value.getLicitacion(route.params.id).then(({ data }) => {
        licitacion.value.nombre = data.nombre
        licitacion.value.fechaCierre = dayjs(data.fechaCierre).format('DD/MM/YYYY')
        // for (const carpeta of Object.values(data.Carpetas)) {
        //   for (const documentos of Object.values(carpeta.CarpetasDocumentos)) {
        //     dataCargar.value.push({
        //       ...documentos,
        //       carpeta: carpeta.nombre
        //     })
        //   }
        // }
        // dataCargar.value = data.Carpetas.map(item => { return { CarpetasDocumentos: item.CarpetasDocumentos, nombre: item.nombre } })
        dataCargar.value = data.Carpetas
      })
    }

    const getUsers = () => {
      _LicitacionesService.value.getUsers().then(({ data }) => {
        users.value.push({ id: null, fullName: 'Sin Asignar' })
        users.value.push(...data)
      })
    }

    const verDocumento = (ruta) => {
      if (ruta) {
        if (!modalEmbedPdf.value.maximized) modalEmbedPdf.value.maximize()
        pathConsolidado.value = ruta
        modalPdf.value = !modalPdf.value
        _LicitacionesService.value.getPdf(ruta).then((url) => {
          rutaConsolidado.value = url + '#toolbar=1&view=FitH&FitV&zoom=100'
        })
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Advertencia',
          detail: 'No hay documento cargado',
          life: 3000
        })
      }
    }

    const cargarDocumento = (id) => {
      documento.value.click()
      cargarDocumentoId.value = id
    }

    const onUpload = async (event) => {
      const formData = new FormData()
      formData.append('licitacionId', route.params.id)
      formData.append('file', event.files[0])
      _LicitacionesService.value.cargarDocumentos(cargarDocumentoId.value, formData).then(({ data }) => {
        if (data.upload) {
          toast.add({
            severity: 'success',
            summary: 'Exito',
            detail: 'Documento cargado con exito',
            life: 3000
          })
          getOnLoad()
        } else {
          toast.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: 'No se cargo ningun documento',
            life: 3000
          })
        }
      })
    }

    const eliminarDocumento = (id) => {
      _LicitacionesService.value.actualizarDocumento(id, { path: null }).then(({ data }) => {
        console.log(data)
        if (data.updated) {
          toast.add({
            severity: 'success',
            summary: 'Exito',
            detail: 'Eliminado con exito',
            life: 3000
          })
          getOnLoad()
        } else {
          toast.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: 'No se elimino ningun documento',
            life: 3000
          })
        }
      })
    }

    const onUser = ({ value }, idDocument) => {
      _LicitacionesService.value.setUser(idDocument, { responsableId: value }).then(({ data }) => {
        console.log(data)
        if (data.updated) {
          toast.add({
            severity: 'success',
            summary: 'Exito',
            detail: 'Asignado con exito',
            life: 3000
          })
          getOnLoad()
        } else {
          toast.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: 'No se asigno nada',
            life: 3000
          })
        }
      })
    }

    const abrio = (event) => {
      document.getElementById(`myAcordion_${event.index}`).classList.add('pi-folder-open')
      document.getElementById(`myAcordion_${event.index}`).classList.remove('pi-folder')
    }

    const cerro = (event) => {
      document.getElementById(`myAcordion_${event.index}`).classList.add('pi-folder')
      document.getElementById(`myAcordion_${event.index}`).classList.remove('pi-folder-open')
    }

    const clearFilter1 = () => {
      filters1.value.global.value = null
    }

    const girarPdf = () => {
      if (pageNumber.value !== null && giro.value !== null) {
        if (pathConsolidado.value) {
          document.getElementById('cargando').style.display = 'flex'
          const embed = document.getElementById('embedPdf')
          const element = document.getElementById('pdf')
          element.parentNode.removeChild(element)
          _LicitacionesService.value.girarPdf({
            pathConsolidado: pathConsolidado.value,
            page: pageNumber.value,
            rotate: parseInt(giro.value)
          }).then((url) => {
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se giro correctamente', life: 3000 })

            document.getElementById('cargando').style.display = 'none'
            embed.append(element)
            // document.getElementById('pdf').contentDocument = location.reload()
          })
        } else {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
        }
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El numero de pagina o cantidad de giro es obligatorio', life: 3000 })
      }
    }

    return {
      clearFilter1,
      dataCargar,
      verDocumento,
      cargarDocumento,
      eliminarDocumento,
      onUpload,
      documento,
      licitacion,
      users,
      onUser,
      expandedRowGroups,
      abrio,
      cerro,
      activeIndex,
      filters1,
      modalPdf,
      rutaConsolidado,
      girarPdf,
      pageNumber,
      giro,
      giros,
      modalEmbedPdf
    }
  }
}
</script>

<style lang="scss" scoped>
.p-dialog-mask{
  z-index: 10000 !important;
}
</style>
